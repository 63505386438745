import { default as driversBzebRKmqnWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue?macro=true";
import { default as indexGVHGTSwFxvMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue?macro=true";
import { default as index1ioKyqHhlRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as cypress_45login5SiocXkW58Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/cypress-login.vue?macro=true";
import { default as dashboardmUDyyYGlatMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/dashboard.vue?macro=true";
import { default as editBRTL0IlKNWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue?macro=true";
import { default as indexFI20eXoLxkMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue?macro=true";
import { default as createvVru6tvduRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue?macro=true";
import { default as indexkb9q08CEfmMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue?macro=true";
import { default as edit7ektvZ1UQWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue?macro=true";
import { default as createZo5sN2RPm1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue?macro=true";
import { default as index80Hg2z8rP2Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue?macro=true";
import { default as editgeCzTtnSvZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue?macro=true";
import { default as indexxxioRYl9GuMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue?macro=true";
import { default as indexGLRAsya0qWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue?macro=true";
import { default as indextQRISQ7ywtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue?macro=true";
import { default as earningsYbNG2zg0DtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings.vue?macro=true";
import { default as exemptionscaa6geHL4GMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions.vue?macro=true";
import { default as indexFYpBU2VUxtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue?macro=true";
import { default as paymentsqzqu6NGoMEMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payments.vue?macro=true";
import { default as adjustmentsibWlaTv4agMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments.vue?macro=true";
import { default as editDteVVCILqmMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/edit.vue?macro=true";
import { default as indexg65cBOE0QUMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/index.vue?macro=true";
import { default as editDW3Q0k3ehzMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/edit.vue?macro=true";
import { default as indexkfARqWQafFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/index.vue?macro=true";
import { default as createaCXKKOKEM8Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/create.vue?macro=true";
import { default as indexDZ1ZKmYxZEMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/index.vue?macro=true";
import { default as createrigcj8amTZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/create.vue?macro=true";
import { default as indexfyrWDmfkBPMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/index.vue?macro=true";
import { default as indexlAlrOY1Ha1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue?macro=true";
import { default as loginh8fMddjfe6Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue?macro=true";
import { default as indexAcKfr6BYDFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue?macro=true";
import { default as categories2ZAnxxvOvBMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories.vue?macro=true";
import { default as indexWd6uFtocItMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue?macro=true";
import { default as indexnrmra44dkFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue?macro=true";
export default [
  {
    name: driversBzebRKmqnWMeta?.name ?? "agreements-id-drivers",
    path: driversBzebRKmqnWMeta?.path ?? "/agreements/:id()/drivers",
    meta: driversBzebRKmqnWMeta || {},
    alias: driversBzebRKmqnWMeta?.alias || [],
    redirect: driversBzebRKmqnWMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue").then(m => m.default || m)
  },
  {
    name: indexGVHGTSwFxvMeta?.name ?? "agreements-id",
    path: indexGVHGTSwFxvMeta?.path ?? "/agreements/:id()",
    meta: indexGVHGTSwFxvMeta || {},
    alias: indexGVHGTSwFxvMeta?.alias || [],
    redirect: indexGVHGTSwFxvMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1ioKyqHhlRMeta?.name ?? "agreements",
    path: index1ioKyqHhlRMeta?.path ?? "/agreements",
    meta: index1ioKyqHhlRMeta || {},
    alias: index1ioKyqHhlRMeta?.alias || [],
    redirect: index1ioKyqHhlRMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue").then(m => m.default || m)
  },
  {
    name: cypress_45login5SiocXkW58Meta?.name ?? "cypress-login",
    path: cypress_45login5SiocXkW58Meta?.path ?? "/cypress-login",
    meta: cypress_45login5SiocXkW58Meta || {},
    alias: cypress_45login5SiocXkW58Meta?.alias || [],
    redirect: cypress_45login5SiocXkW58Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/cypress-login.vue").then(m => m.default || m)
  },
  {
    name: dashboardmUDyyYGlatMeta?.name ?? "dashboard",
    path: dashboardmUDyyYGlatMeta?.path ?? "/dashboard",
    meta: dashboardmUDyyYGlatMeta || {},
    alias: dashboardmUDyyYGlatMeta?.alias || [],
    redirect: dashboardmUDyyYGlatMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: editBRTL0IlKNWMeta?.name ?? "documents-id-edit",
    path: editBRTL0IlKNWMeta?.path ?? "/documents/:id()/edit",
    meta: editBRTL0IlKNWMeta || {},
    alias: editBRTL0IlKNWMeta?.alias || [],
    redirect: editBRTL0IlKNWMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFI20eXoLxkMeta?.name ?? "documents-id",
    path: indexFI20eXoLxkMeta?.path ?? "/documents/:id()",
    meta: indexFI20eXoLxkMeta || {},
    alias: indexFI20eXoLxkMeta?.alias || [],
    redirect: indexFI20eXoLxkMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createvVru6tvduRMeta?.name ?? "documents-create",
    path: createvVru6tvduRMeta?.path ?? "/documents/create",
    meta: createvVru6tvduRMeta || {},
    alias: createvVru6tvduRMeta?.alias || [],
    redirect: createvVru6tvduRMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue").then(m => m.default || m)
  },
  {
    name: indexkb9q08CEfmMeta?.name ?? "documents",
    path: indexkb9q08CEfmMeta?.path ?? "/documents",
    meta: indexkb9q08CEfmMeta || {},
    alias: indexkb9q08CEfmMeta?.alias || [],
    redirect: indexkb9q08CEfmMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: edit7ektvZ1UQWMeta?.name ?? "documents-types-id-edit",
    path: edit7ektvZ1UQWMeta?.path ?? "/documents/types/:id()/edit",
    meta: edit7ektvZ1UQWMeta || {},
    alias: edit7ektvZ1UQWMeta?.alias || [],
    redirect: edit7ektvZ1UQWMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createZo5sN2RPm1Meta?.name ?? "documents-types-create",
    path: createZo5sN2RPm1Meta?.path ?? "/documents/types/create",
    meta: createZo5sN2RPm1Meta || {},
    alias: createZo5sN2RPm1Meta?.alias || [],
    redirect: createZo5sN2RPm1Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue").then(m => m.default || m)
  },
  {
    name: index80Hg2z8rP2Meta?.name ?? "documents-types",
    path: index80Hg2z8rP2Meta?.path ?? "/documents/types",
    meta: index80Hg2z8rP2Meta || {},
    alias: index80Hg2z8rP2Meta?.alias || [],
    redirect: index80Hg2z8rP2Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue").then(m => m.default || m)
  },
  {
    name: editgeCzTtnSvZMeta?.name ?? "documents-vehicle-changes-id-edit",
    path: editgeCzTtnSvZMeta?.path ?? "/documents/vehicle-changes/:id()/edit",
    meta: editgeCzTtnSvZMeta || {},
    alias: editgeCzTtnSvZMeta?.alias || [],
    redirect: editgeCzTtnSvZMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexxxioRYl9GuMeta?.name ?? "documents-vehicle-changes-id",
    path: indexxxioRYl9GuMeta?.path ?? "/documents/vehicle-changes/:id()",
    meta: indexxxioRYl9GuMeta || {},
    alias: indexxxioRYl9GuMeta?.alias || [],
    redirect: indexxxioRYl9GuMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLRAsya0qWMeta?.name ?? "documents-vehicle-changes",
    path: indexGLRAsya0qWMeta?.path ?? "/documents/vehicle-changes",
    meta: indexGLRAsya0qWMeta || {},
    alias: indexGLRAsya0qWMeta?.alias || [],
    redirect: indexGLRAsya0qWMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue").then(m => m.default || m)
  },
  {
    name: indextQRISQ7ywtMeta?.name ?? "driver-partners-id",
    path: indextQRISQ7ywtMeta?.path ?? "/driver-partners/:id()",
    meta: indextQRISQ7ywtMeta || {},
    alias: indextQRISQ7ywtMeta?.alias || [],
    redirect: indextQRISQ7ywtMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: earningsYbNG2zg0DtMeta?.name ?? "driver-partners-earnings",
    path: earningsYbNG2zg0DtMeta?.path ?? "/driver-partners/earnings",
    meta: earningsYbNG2zg0DtMeta || {},
    alias: earningsYbNG2zg0DtMeta?.alias || [],
    redirect: earningsYbNG2zg0DtMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings.vue").then(m => m.default || m)
  },
  {
    name: exemptionscaa6geHL4GMeta?.name ?? "driver-partners-exemptions",
    path: exemptionscaa6geHL4GMeta?.path ?? "/driver-partners/exemptions",
    meta: exemptionscaa6geHL4GMeta || {},
    alias: exemptionscaa6geHL4GMeta?.alias || [],
    redirect: exemptionscaa6geHL4GMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions.vue").then(m => m.default || m)
  },
  {
    name: indexFYpBU2VUxtMeta?.name ?? "driver-partners",
    path: indexFYpBU2VUxtMeta?.path ?? "/driver-partners",
    meta: indexFYpBU2VUxtMeta || {},
    alias: indexFYpBU2VUxtMeta?.alias || [],
    redirect: indexFYpBU2VUxtMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue").then(m => m.default || m)
  },
  {
    name: paymentsqzqu6NGoMEMeta?.name ?? "driver-partners-payments",
    path: paymentsqzqu6NGoMEMeta?.path ?? "/driver-partners/payments",
    meta: paymentsqzqu6NGoMEMeta || {},
    alias: paymentsqzqu6NGoMEMeta?.alias || [],
    redirect: paymentsqzqu6NGoMEMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payments.vue").then(m => m.default || m)
  },
  {
    name: adjustmentsibWlaTv4agMeta?.name ?? "invoices-adjustments",
    path: adjustmentsibWlaTv4agMeta?.path ?? "/invoices/adjustments",
    meta: adjustmentsibWlaTv4agMeta || {},
    alias: adjustmentsibWlaTv4agMeta?.alias || [],
    redirect: adjustmentsibWlaTv4agMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments.vue").then(m => m.default || m)
  },
  {
    name: editDteVVCILqmMeta?.name ?? "invoices-billing-profiles-id-edit",
    path: editDteVVCILqmMeta?.path ?? "/invoices/billing-profiles/:id()/edit",
    meta: editDteVVCILqmMeta || {},
    alias: editDteVVCILqmMeta?.alias || [],
    redirect: editDteVVCILqmMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexg65cBOE0QUMeta?.name ?? "invoices-billing-profiles-id",
    path: indexg65cBOE0QUMeta?.path ?? "/invoices/billing-profiles/:id()",
    meta: indexg65cBOE0QUMeta || {},
    alias: indexg65cBOE0QUMeta?.alias || [],
    redirect: indexg65cBOE0QUMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: editDW3Q0k3ehzMeta?.name ?? "invoices-billing-profiles-id-items-itemId-edit",
    path: editDW3Q0k3ehzMeta?.path ?? "/invoices/billing-profiles/:id()/items/:itemId()/edit",
    meta: editDW3Q0k3ehzMeta || {},
    alias: editDW3Q0k3ehzMeta?.alias || [],
    redirect: editDW3Q0k3ehzMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexkfARqWQafFMeta?.name ?? "invoices-billing-profiles-id-items-itemId",
    path: indexkfARqWQafFMeta?.path ?? "/invoices/billing-profiles/:id()/items/:itemId()",
    meta: indexkfARqWQafFMeta || {},
    alias: indexkfARqWQafFMeta?.alias || [],
    redirect: indexkfARqWQafFMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: createaCXKKOKEM8Meta?.name ?? "invoices-billing-profiles-id-items-create",
    path: createaCXKKOKEM8Meta?.path ?? "/invoices/billing-profiles/:id()/items/create",
    meta: createaCXKKOKEM8Meta || {},
    alias: createaCXKKOKEM8Meta?.alias || [],
    redirect: createaCXKKOKEM8Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/create.vue").then(m => m.default || m)
  },
  {
    name: indexDZ1ZKmYxZEMeta?.name ?? "invoices-billing-profiles-id-items",
    path: indexDZ1ZKmYxZEMeta?.path ?? "/invoices/billing-profiles/:id()/items",
    meta: indexDZ1ZKmYxZEMeta || {},
    alias: indexDZ1ZKmYxZEMeta?.alias || [],
    redirect: indexDZ1ZKmYxZEMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/index.vue").then(m => m.default || m)
  },
  {
    name: createrigcj8amTZMeta?.name ?? "invoices-billing-profiles-create",
    path: createrigcj8amTZMeta?.path ?? "/invoices/billing-profiles/create",
    meta: createrigcj8amTZMeta || {},
    alias: createrigcj8amTZMeta?.alias || [],
    redirect: createrigcj8amTZMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/create.vue").then(m => m.default || m)
  },
  {
    name: indexfyrWDmfkBPMeta?.name ?? "invoices-billing-profiles",
    path: indexfyrWDmfkBPMeta?.path ?? "/invoices/billing-profiles",
    meta: indexfyrWDmfkBPMeta || {},
    alias: indexfyrWDmfkBPMeta?.alias || [],
    redirect: indexfyrWDmfkBPMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexlAlrOY1Ha1Meta?.name ?? "invoices",
    path: indexlAlrOY1Ha1Meta?.path ?? "/invoices",
    meta: indexlAlrOY1Ha1Meta || {},
    alias: indexlAlrOY1Ha1Meta?.alias || [],
    redirect: indexlAlrOY1Ha1Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: loginh8fMddjfe6Meta?.name ?? "login",
    path: loginh8fMddjfe6Meta?.path ?? "/login",
    meta: loginh8fMddjfe6Meta || {},
    alias: loginh8fMddjfe6Meta?.alias || [],
    redirect: loginh8fMddjfe6Meta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexAcKfr6BYDFMeta?.name ?? "news-id",
    path: indexAcKfr6BYDFMeta?.path ?? "/news/:id()",
    meta: indexAcKfr6BYDFMeta || {},
    alias: indexAcKfr6BYDFMeta?.alias || [],
    redirect: indexAcKfr6BYDFMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: categories2ZAnxxvOvBMeta?.name ?? "news-categories",
    path: categories2ZAnxxvOvBMeta?.path ?? "/news/categories",
    meta: categories2ZAnxxvOvBMeta || {},
    alias: categories2ZAnxxvOvBMeta?.alias || [],
    redirect: categories2ZAnxxvOvBMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories.vue").then(m => m.default || m)
  },
  {
    name: indexWd6uFtocItMeta?.name ?? "news",
    path: indexWd6uFtocItMeta?.path ?? "/news",
    meta: indexWd6uFtocItMeta || {},
    alias: indexWd6uFtocItMeta?.alias || [],
    redirect: indexWd6uFtocItMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexnrmra44dkFMeta?.name ?? "profile",
    path: indexnrmra44dkFMeta?.path ?? "/profile",
    meta: indexnrmra44dkFMeta || {},
    alias: indexnrmra44dkFMeta?.alias || [],
    redirect: indexnrmra44dkFMeta?.redirect,
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue").then(m => m.default || m)
  }
]