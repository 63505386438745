import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'
import type {
  Agreement,
  BillingItem,
  BillingItemRequest,
  BillingProfile,
  BillingProfileRequest,
  BillingProfileSyncDriversRequest,
  DeclineDocumentRequest,
  DeclineVehicleChangeRequest,
  DispatchSystem,
  Document,
  DocumentRequest,
  DocumentType,
  DocumentTypeRequest,
  Driver,
  NewsCategory,
  NewsPost,
  VehicleChange,
  VehicleChangeRequest,
} from '~/api/types/DriverPortal'
import type { FetchOptions } from 'ofetch'

export default class DriverPortalService extends ApiServiceBase {
  /*
   * Agreements
   */
  async getAllAgreements(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Agreement[]>> {
    return await this.call('/v1/dpp/agreements', options)
  }

  async getAgreement(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Agreement>> {
    return await this.call(`/v1/dpp/agreements/${id}`, options)
  }

  async getAllDriversForAgreement(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/v1/dpp/agreements/${id}/drivers`, options)
  }

  /*
   * Billing Items
   */
  async getAllBillingItems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingItem[]>> {
    return await this.call('/v1/dpp/billing-items', options)
  }

  async getBillingItem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, options)
  }

  async createBillingItem(data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call('/v1/dpp/billing-items', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingItem(id: string | number, data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingItem(id: string | number): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Billing Profiles
   */
  async getAllBillingProfiles(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingProfile[]>> {
    return await this.call('/v1/dpp/billing-profiles', options)
  }

  async getBillingProfile(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, options)
  }

  async createBillingProfile(data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call('/v1/dpp/billing-profiles', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingProfile(id: string | number, data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingProfile(id: string | number): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, {
      method: 'delete',
    })
  }

  async syncDriversWithBillingProfile(
    id: string | number,
    data: BillingProfileSyncDriversRequest
  ): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}/drivers`, {
      method: 'post',
      body: data,
    })
  }

  /*
   * Dispatch Systems
   */
  async getAllDispatchSystems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DispatchSystem[]>> {
    return await this.call('/v1/dpp/dispatch-systems', options)
  }

  /*
   * Documents
   */
  async getAllDocuments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Document[]>> {
    return await this.call('/v1/dpp/documents', options)
  }

  async createDocument(data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call('/v1/dpp/documents', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async getDocument(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, options)
  }

  async updateDocument(id: string | number, data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async deleteDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'delete',
    })
  }

  async approveDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/approve`, {
      method: 'post',
    })
  }

  async declineDocument(id: string | number, data: DeclineDocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }

  /*
   * Document Types
   */
  async getAllDocumentTypes(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DocumentType[]>> {
    return await this.call('/v1/dpp/document-types', options)
  }

  async createDocumentType(data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call('/v1/dpp/document-types', {
      method: 'post',
      body: data,
    })
  }

  async getDocumentType(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, options)
  }

  async updateDocumentType(id: string | number, data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteDocumentType(id: string | number): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Drivers
   */
  async getAllDrivers(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call('/v1/dpp/drivers', options)
  }

  /*
   * News categories
   */
  async getAllNewsCategories(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsCategory[]>> {
    return await this.call('/v1/dpp/news-categories', options)
  }

  /*
   * News posts
   */
  async getAllNewsPosts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsPost[]>> {
    return await this.call('/v1/dpp/news-posts', options)
  }

  async getNewsPost(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, options)
  }

  /*
   * Vehicle changes
   */
  async getAllVehicleChanges(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<VehicleChange[]>> {
    return await this.call('/v1/dpp/vehicle-changes', options)
  }

  async getVehicleChange(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, options)
  }

  async updateVehicleChange(id: string | number, data: VehicleChangeRequest): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async approveVehicleChange(id: string | number): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/approve`, {
      method: 'post',
    })
  }

  async declineVehicleChange(
    id: string | number,
    data: DeclineVehicleChangeRequest
  ): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }
}
